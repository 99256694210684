import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";

const Main = () => {
  return (
    <Router>
      <div className="wrapper">
        <Routes>
          <Route exact path="/" element={<Home />} />
      
        </Routes>
      </div>
    </Router>
  );
};

export default Main;
