import React, { useState } from "react";
import Header from "../../component/Header/Header";
import Footer from "../../component/Footer/Footer";
import Banner1 from "../../assets/images/mobbanner1.png";
import Banner2 from "../../assets/images/mobbanner2.png";
import Banner3 from "../../assets/images/mobbanner3.png";
import Slider from "react-slick";
import Img from "../../assets/images/mumbai1711622216.png";
import Img2 from "../../assets/images/sunrisers-hyderabad1711962748.png";

export default function Home() {
  const [selectedTeam, setSelectedTeam] = useState(0);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const handlePredict = (event) => {
    event.preventDefault();
    const selectedValue = parseInt(event.target.querySelector("select").value);
    setSelectedTeam(selectedValue);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    urlParams.set("country_code", "AE");
    urlParams.set("token", "212f781a-0c12-43a0-86de-ba2c8528a581");
    urlParams.set("publisher_id", "11");
    urlParams.set("q", getTeamName(selectedValue));
    const qs = new URLSearchParams(urlParams).toString();
    const url = `/clk?${qs}`;
    window.location.href = url;
    
  };

  return (
    <div>
      <Header />
      <div>
        <Slider {...settings}>
          <div>
            <img src={Banner1} className="banner" alt="banner1" />
          </div>
          <div>
            <img src={Banner2} className="banner" alt="banner2" />
          </div>
          <div>
            <img src={Banner3} className="banner" alt="banner3" />
          </div>
        </Slider>
      </div>
      <div className="text-div">
        <h1>Predict. Play. Win Big!</h1>
        <h4>
          Get ready to make your mark in the ultimate battle of predictions and
          <br />
          Win four iPhone15 Pro Max!
        </h4>
        <p>
          **Please make sure you have entered the player name without any
          spelling mistake
        </p>
      </div>
      <div className="predictperson">
        <div className="winner_cap person_grid">
          <div className="predict_grid_head">
            <h2>IPL Title Winner</h2>
          </div>
          <form className="text-center" onSubmit={handlePredict}>
            <select className="mt-3 required">
              <option value={0}>Select team</option>
              <option value={1}>Mumbai Indians</option>
              <option value={2}>Chennai Super Kings</option>
              <option value={3}>Kolkata Knight Riders</option>
              <option value={4}>Rajasthan Royals</option>
              <option value={5}>Delhi Capitals</option>
              <option value={6}>Gujarat Titans</option>
              <option value={7}>Lucknow Super Giants</option>
              <option value={8}>Punjab Kings</option>
              <option value={9}>Royal Challengers Bengaluru</option>
              <option value={10}>Sunrisers Hyderabad</option>
            </select>
            <br />
            <input
              className="mt-4 btn btn-secondary contact_form_btn"
              value="Predict"
              type="submit"
            ></input>
          </form>
          {/* Display the selected team */}
          {/* {selectedTeam !== 0 && (
            <div className="mt-3">You predicted: {getTeamName(selectedTeam)}</div>
          )} */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

function getTeamName(teamValue) {
  switch (teamValue) {
    case 1:
      return "Mumbai Indians";
    case 2:
      return "Chennai Super Kings";
    case 3:
      return "Kolkata Knight Riders";
    case 4:
      return "Rajasthan Royals";
    case 5:
      return "Delhi Capitals";
    case 6:
      return "Gujarat Titans";
    case 7:
      return "Lucknow Super Giants";
    case 8:
      return "Punjab Kings";
    case 9:
      return "Royal Challengers Bengaluru";
    case 10:
      return "Sunrisers Hyderabad";
    default:
      return "";
  }
}
